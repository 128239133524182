import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IonicStorageService {
  private secretKey = environment.secret;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this.storage = storage;
  }

  public async setItem(key: string, value: any): Promise<void> {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), this.secretKey).toString();
    return this.storage.set(key, encryptedValue);
  }

  public async getItem(key: string): Promise<object | null> {
    const encryptedValue = await this.storage.get(key);

    if (encryptedValue) {
      const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, this.secretKey);
      return JSON.parse(JSON.parse(decryptedValue.toString(CryptoJS.enc.Utf8)));
    }

    return null;
  }

  public removeItem(key: string): Promise<void> {
    return this.storage.remove(key);
  }

  public checkItem(key: string): Promise<boolean> {
    return this.storage.get(key).then((item) => item != null);
  }
}
