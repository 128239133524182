import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { convertKeysToCamelCase, convertKeysToSnakeCase } from '../../classes/camelize';

enum RequestTypes {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  GET = 'GET',
  DELETE = 'DELETE',
}

@Injectable()
export class ConvertCaseInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.endsWith('.json')) {
      return next.handle(req);
    }

    if ([RequestTypes.POST, RequestTypes.PATCH, RequestTypes.PUT].includes(req.method as RequestTypes) && !(req.body instanceof FormData)) {
      req = req.clone({ body: convertKeysToSnakeCase(req.body) });
    }

    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          return event.clone({ body: convertKeysToCamelCase(event.body) });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.error) {
          const convertedError = convertKeysToCamelCase(error.error);
          const modifiedError = new HttpErrorResponse({
            error: convertedError,
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url,
          });
          return throwError(modifiedError);
        }
        return throwError(error);
      }),
    );
  }
}
