import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { checkTokenValidity } from '@app/shared/store/user/utils/helpers';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import * as AuthActions from '@app/shared/store/user/user.actions';
import { AuthService, CustomTokenRefresh } from '../openapi';
import { AuthService as AuthApiService } from 'src/app/services/api/core/auth.service';
import { selectAccess, selectRefresh } from '@app/shared/store/user/user.reducer';

@UntilDestroy()
@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  private readonly urlsToSkip: string[] = ['version', 's3.amazonaws.com/tmp', 's3.amazonaws.com'];
  private readonly refreshRequestUrl = '/auth/token/refresh';
  private accessToken!: string;
  private refreshToken!: string;
  private isRefreshing = false;
  private refreshTokenRequest!: Observable<CustomTokenRefresh>;

  constructor(
    private readonly authService: AuthService,
    private readonly authApiService: AuthApiService,
    public readonly store: Store,
  ) {
    this.store
      .pipe(select(selectAccess))
      .pipe(untilDestroyed(this))
      .subscribe((access: string) => {
        this.accessToken = access;
      });

    this.store
      .pipe(select(selectRefresh))
      .pipe(untilDestroyed(this))
      .subscribe((refresh: string) => {
        this.refreshToken = refresh;
      });
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(this.refreshRequestUrl)) {
      return next.handle(request);
    }

    if (this.urlsToSkip.some((url) => request.url.includes(url))) {
      return next.handle(request);
    }

    if (this.accessToken && checkTokenValidity(this.accessToken)) {
      request = this.addAuthorizationHeader(request, this.accessToken);
      return next.handle(request);
    }

    if (this.refreshToken && checkTokenValidity(this.refreshToken)) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;

        this.refreshTokenRequest = this.authService.authTokenRefreshCreate({ refresh: this.refreshToken } as any).pipe(
          tap((data: CustomTokenRefresh) => {
            this.authApiService.setTokensToStorage(data);
            this.store.dispatch(AuthActions.signInSuccess({ data, redirection: false }));

            this.accessToken = data?.['access'];
            this.isRefreshing = false;
          }),
          catchError((error) => {
            this.store.dispatch(AuthActions.setLoader({ data: false }));
            this.store.dispatch(AuthActions.clearSession());
            this.store.dispatch(AuthActions.setLoader({ data: false }));
            return throwError(error);
          }),
          finalize(() => {
            this.isRefreshing = false;
          }),
        );

        return this.refreshTokenRequest.pipe(
          switchMap(() => {
            request = this.addAuthorizationHeader(request, this.accessToken);
            return next.handle(request);
          }),
        );
      } else {
        return this.refreshTokenRequest.pipe(
          switchMap(() => {
            request = this.addAuthorizationHeader(request, this.accessToken);
            return next.handle(request);
          }),
        );
      }
    }

    return next.handle(request);
  }

  private addAuthorizationHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
