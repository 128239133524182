import { Injectable } from '@angular/core';
import { Animation, AnimationController, ModalController } from '@ionic/angular';
import { ModalResults } from '@app/services/modal/modal.interface';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private modalController: ModalController,
    private animationCtrl: AnimationController,
  ) {}

  public async presentModal(
    component,
    cssClass?: string,
    componentProps?: unknown,
    backdropClose: boolean = true,
  ): Promise<ModalResults | unknown> {
    const modal = await this.modalController.create({
      component,
      cssClass: cssClass ?? '',
      enterAnimation: (baseEl: HTMLElement) => this.enterAnimation(baseEl),
      leaveAnimation: (baseEl: HTMLElement) => this.leaveAnimation(baseEl),
      componentProps,
      backdropDismiss: backdropClose,
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    return data;
  }

  public async dismissModal(result?: unknown): Promise<void> {
    await this.modalController.dismiss(result);
  }

  private enterAnimation(baseEl: HTMLElement): Animation {
    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(baseEl.shadowRoot.querySelector('ion-backdrop') as HTMLElement)
      .fromTo('opacity', '0', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(baseEl.shadowRoot.querySelector('.modal-wrapper') as HTMLElement)
      .fromTo('transform', 'translateX(100%)', 'translateX(0)')
      .fromTo('opacity', '0', '1');

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(400)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }

  private leaveAnimation(baseEl: HTMLElement): Animation {
    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(baseEl.shadowRoot.querySelector('ion-backdrop') as HTMLElement)
      .fromTo('opacity', 'var(--backdrop-opacity)', '0');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(baseEl.shadowRoot.querySelector('.modal-wrapper') as HTMLElement)
      .fromTo('transform', 'translateX(0)', 'translateX(100%)')
      .fromTo('opacity', '1', '0');

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-in')
      .duration(400)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }
}
