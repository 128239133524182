import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as UserActions from '@app/shared/store/user/user.actions';
import { Store } from '@ngrx/store';
import { ModalService } from '@app/services/modal/modal.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly modalService: ModalService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.store.dispatch(UserActions.clearSession());
          this.modalService.dismissModal();
        }

        return throwError(error);
      }),
    );
  }
}
