import { createAction, props } from '@ngrx/store';
import {
  AccountRead,
  AccountTokenObtainPair,
  AccountTokenObtainPairResponse,
  CustomTokenRefresh,
  UserProfileUpdate,
} from '@app/shared/openapi';

export const signIn = createAction(
  '[Auth] Sign In',
  props<{
    data: AccountTokenObtainPair;
  }>(),
);

export const setLoader = createAction(
  '[Auth] Set Loader',
  props<{
    data: boolean;
  }>(),
);

export const setSystemLoader = createAction(
  '[Auth] Set System Loader',
  props<{
    data: boolean;
  }>(),
);

export const refreshUser = createAction('[Auth] Refresh User');

export const signInSuccess = createAction(
  '[Auth] Sign In Success',
  props<{
    data: AccountTokenObtainPairResponse | CustomTokenRefresh;
    redirection: boolean;
  }>(),
);

export const signInFailure = createAction(
  '[Auth] Sign In Failure',
  props<{
    error: Error;
  }>(),
);

export const clearSession = createAction('[Auth] Clear Session');

export const getSelfUser = createAction(
  '[Auth] Get Self User',
  props<{
    data: string;
  }>(),
);

export const getSelfUserSuccess = createAction(
  '[Auth] Get Self User Success',
  props<{
    data: AccountRead;
    login?: boolean;
  }>(),
);

export const getSelfUserSuccessFailure = createAction(
  '[Auth] Get Self User Failure',
  props<{
    error: Error;
  }>(),
);

export const updateUserInfo = createAction(
  '[Auth] Update User Info',
  props<{
    data: UserProfileUpdate;
  }>(),
);

export const logout = createAction(
  '[Auth] Logout',
  props<{
    logoutAll: boolean;
  }>(),
);
