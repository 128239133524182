import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
const Storage = Preferences;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public async set(key: string, value: any) {
    await Storage.set({
      key,
      value: JSON.stringify(value),
    });
  }

  public async get(key: string) {
    const { value } = await Storage.get({ key });
    return value ? JSON.parse(value) : undefined;
  }

  public async remove(key: string) {
    await Storage.remove({ key });
  }

  public async keys() {
    const { keys } = await Storage.keys();
    return keys;
  }

  public async clear() {
    await Storage.clear();
  }
}
