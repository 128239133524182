import { Injectable } from '@angular/core';
import { ClientAPIService } from '../controllers/client-api.service';
import { SystemAPIService } from '../controllers/system-api.service';
import { UserAPIService } from '../controllers/user-api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public system: SystemAPIService,
    public user: UserAPIService,
    public client: ClientAPIService,
  ) {}
}
