import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/store/user/utils/auth-guard';

const routes: Routes = [
  {
    path: 'auth',
    redirectTo: 'login',
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/children/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/auth/children/singup/singup.module').then((m) => m.SingupPageModule),
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/auth/children/password-reset/password-reset.module').then((m) => m.PasswordResetPageModule),
  },
  {
    path: 'invite',
    loadChildren: () => import('./pages/auth/children/invite/invite.module').then((m) => m.InviteModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/client/client.module').then((m) => m.ClientPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
