/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Finding } from './finding';


export interface BTestResult { 
    findings: Array<Finding>;
    readonly id?: string;
    passed?: boolean | null;
    risk?: BTestResult.RiskEnum | null;
    status: BTestResult.StatusEnum;
}
export namespace BTestResult {
    export type RiskEnum = 'High' | 'Medium' | 'Low' | 'Info';
    export const RiskEnum = {
        High: 'High' as RiskEnum,
        Medium: 'Medium' as RiskEnum,
        Low: 'Low' as RiskEnum,
        Info: 'Info' as RiskEnum
    };
    export type StatusEnum = 'To be done' | 'In progress' | 'Finished' | 'Failed';
    export const StatusEnum = {
        ToBeDone: 'To be done' as StatusEnum,
        InProgress: 'In progress' as StatusEnum,
        Finished: 'Finished' as StatusEnum,
        Failed: 'Failed' as StatusEnum
    };
}


