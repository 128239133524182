import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalService } from '@app/services/modal/modal.service';
import { ErrorModalComponent } from '@app/pages/client/children/scans/children/start-redirect/ui/error-modal/error-modal.component';
import { API_SKIP_URLS } from '@app/shared/interceptors/skip-error-handler';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private modalService: ModalService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldSkip = API_SKIP_URLS.some((skipUrl) => req.url.endsWith(skipUrl.url) && req.method === skipUrl.method.toUpperCase());

    if (shouldSkip) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const showErrorModal = req.headers.get('show-error-modal') !== 'false';
        if (showErrorModal && error.status >= 400 && error.status !== 401) {
          this.modalService.presentModal(
            ErrorModalComponent,
            'auto-modal',
            {
              data: {
                error: {
                  status: error.status,
                  url: error.url,
                  error: error.error,
                },
              },
            },
            false,
          );
        }
        return throwError(error);
      }),
    );
  }
}
