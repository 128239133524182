/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyRead } from './companyRead';


export interface ExternalIPRead { 
    alias?: string | null;
    company?: CompanyRead;
    readonly createdAt?: string;
    readonly id?: string;
    ipAddress: string;
    readonly updatedAt?: string;
}

