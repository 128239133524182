/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConnectivityDevice } from './connectivityDevice';


export interface ConnectivityRead { 
    alias?: string | null;
    readonly createdAt?: string;
    description?: string | null;
    device: ConnectivityDevice;
    readonly endpoint?: string;
    readonly endpointType?: string;
    readonly id?: string;
    lastTimeUsed?: string | null;
}

