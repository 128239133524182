import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAPIUserInformationDef } from '../../../api_interfaces/core.js';
import { AuthService } from '../core/auth.service';
import { PasswordReset, PasswordResetCheck, UserService } from '@app/shared/openapi';

@Injectable({
  providedIn: 'root',
})
export class UserAPIService {
  constructor(
    private _auth: AuthService,
    private readonly userService: UserService,
  ) {}

  public getInformation(): Observable<IAPIUserInformationDef> {
    return this._auth._getUserInformation();
  }

  public logout(): Promise<any> {
    return this._auth._logout();
  }

  public sendValidationCodeSignupEmail(data: { email: string }): Observable<{ token: string }> {
    return this._auth._post(`user/email_confirmation`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  public verifyValidationCodeSignupEmail(data: { token: string }): Observable<{ emailVerificationToken: string }> {
    return this._auth._post(`user/code_verification`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  /*   public setUserInformation(data: {
                        data: {
                          // name?: string,
                          // email?: string,
                          // description?: string,
                          profile_picture?: string,
                          // tax_id?: string,
                          // phone?: string,
                          // allow_notifications?: boolean,
                        } | string,
                        file?: {
                          blob: any,
                          name: string,
                        },
                      }): Observable<IAPIClientAccountDef> {
                        data.data = JSON.stringify(data.data);
                        return this._auth._post('account/change_user_information.json', data).pipe(tap({
                          complete: () => this._auth._checkLogin(),
                          error: () => this._auth._checkLogin(),
                        }));
                      } */

  public changePassword(data: { id: number; old_password: string; password: string }): Observable<boolean> {
    return this._auth._patch(`user/password/${data.id}`, data);
  }

  public login(data: { email: string; password: string; account_alias: string }): Observable<IAPIUserInformationDef> {
    return this._auth._post('auth/signin', data);
  }

  public signup(data: { email: string; password1: string; password2: string; account_alias: string }): Observable<IAPIUserInformationDef> {
    //return this._auth._post('auth/signup', data, { headers: { 'Content-Type': 'application/json' } });
    return this._auth._post('user/', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  public sendPasswordResetEmail(data: { email: string }): Observable<PasswordReset> {
    return this.userService.userPasswordRequestCreate(data);
  }

  public resetPassword(data: { password: string; token: string }): Observable<PasswordResetCheck> {
    return this.userService.userPasswordResetCreate({
      newPassword: data.password,
      token: data.token,
    });
  }

  public setTempPermissions(data: { user: number; permissions: number[] }): Observable<IAPIUserInformationDef> {
    return this._auth._put('auth/permissions/add', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  /*   public deleteAccount(data: {
                        password: string,
                      }): Observable<boolean> {
                        return this._auth._post('account/delete.json', data).pipe(tap({
                          next: () => this._auth._resetAuthState(),
                          error: () => { },
                        }));
                      } */
}
