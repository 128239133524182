import { AuthService } from '../core/auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  IAPIConectivityDef,
  IAPIConectivityTypeDef,
  IAPISimCardDef,
  IAPIVPNDeviceDef,
  IAPIVPNRouterDef,
} from 'src/app/api_interfaces/conectivities';
import { IAPIProjectDef, IAPIProjectTypeDef } from 'src/app/api_interfaces/projects';
import { IAPIUseCaseDef } from 'src/app/api_interfaces/useCases';
import { IAPIDeviceDef, IAPIDeviceTypeDef } from 'src/app/api_interfaces/devices';
import { IAPIPaginationHandlerDefApiReturn } from 'src/app/api_interfaces/core';
import { map } from 'rxjs/operators';
import { IAPINewScanDef, IAPIScanDef, IAPIScanPlanDef, IAPIScanTrackDef, IAPIScanTypeDef } from 'src/app/api_interfaces/scans';
import { IAPITestStandardDef } from 'src/app/api_interfaces/tests';
import { IAPIIndustryDef } from 'src/app/api_interfaces/industries';
import { IAPICountryDef } from 'src/app/api_interfaces/countries';
import { IAPISubscriptionPlanDef } from 'src/app/api_interfaces/subscriptionPlans';
import { TableReferences } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api.base.service';

@Injectable({
  providedIn: 'root',
})
class SIMCardsAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    account?: number;
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPISimCardDef>> {
    return this._auth._get(`sim-card/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
class VPNDevicesAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    account?: number;
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIVPNDeviceDef>> {
    return this._auth._get(`vpn-device/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
class VPNRouterAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    account?: number;
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIVPNRouterDef>> {
    return this._auth._get(`vpn-router/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
class ConectivityTypesAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    account?: number;
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIConectivityTypeDef>> {
    return this._auth._get(`connectivity-type/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return (
          val?.result || val /* .reduce((final, item) => {
                item.table_reference = item.const;
                final.push(item);
                return final;
            }, []) */
        );
      }),
    );
  }

  /* create(data: {
        name: string,// (required)
    }): Observable<IAPIConectivityTypeDef> {
        return this._auth._post('connectivity-type/', data);
    } */

  /*
    DESCONTINUADO
        edit(id: number,
            data: {
                alias: string
                account: number
                tableid: number
                connectivity_type: number
                is_active?: boolean
                is_deleted?: boolean
            }): Observable<IAPIConectivityTypeDef> {
            return this._auth._put(`connectivity-type/${id}`, data);
        }

        DESCONTINUADO
        delete(id: number): Observable<IAPIConectivityTypeDef> {
            return this._auth._patch(`connectivity-type/${id}`);
        } */
}

@Injectable({
  providedIn: 'root',
})
class ConectivitiesAPIService {
  constructor(
    private _auth: AuthService,
    private readonly apiService: ApiService,
    public types: ConectivityTypesAPIService,
  ) {}

  dinamycallyGet(
    endpoint: string,
    params?: {
      filter_account?: number;
      id?: number;
    },
  ): Observable<
    | IAPIPaginationHandlerDefApiReturn<IAPIVPNDeviceDef | IAPISimCardDef | IAPIVPNRouterDef>
    | IAPIVPNDeviceDef
    | IAPISimCardDef
    | IAPIVPNRouterDef
  > {
    return this._auth._get(`${endpoint}${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  get(params?: {
    filter_account?: number;
    id?: number;
    order?: 'id' | 'id' | 'alias' | 'tableid' | 'is_active' | 'createdate' | 'createby';
    limit?: number;
    tableid?: number;
    filter_connectivity_type?: number;
    filter_str?: string;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIConectivityDef> | IAPIConectivityDef> {
    return this.apiService.get(`project/device/connectivity/list${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  getNotUsed(params?: {
    account?: number;
    id?: number;
    order?: 'id' | 'id' | 'alias' | 'tableid' | 'is_active' | 'createdate' | 'createby';
    limit?: number;
    tableid?: number;
    filter_connectivity_type?: number;
    filter_str?: string;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIConectivityDef> | IAPIConectivityDef> {
    return this._auth._get(`connectivity/not_use/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  getNotAssigned(params?: {
    filter_account?: number;
    id?: number;
    order?: 'id' | 'id' | 'alias' | 'tableid' | 'is_active' | 'createdate' | 'createby';
    limit?: number;
    tableid?: number;
    filter_connectivity_type?: number;
    filter_str?: string;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIConectivityDef> | IAPIConectivityDef> {
    return this._auth._get(`connectivity/not_assigned/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  download(id: number, type: string): Observable<Blob> {
    if (type == TableReferences.b_vpn_devices) {
      return this._auth._get(`vpn-device/config-file/${id || ''}`, {}, { responseType: 'blob' });
    } else if (type == TableReferences.b_vpn_router) {
      return this._auth._get(`vpn-router/config-file/${id || ''}`, {}, { responseType: 'blob' });
    }
  }

  create(data: {
    alias: string; // (required)
    account: number; // (required)
    table_id: number; // (required)
    connectivity_type: number; // (required)
    is_active?: boolean; //
    is_deleted?: boolean; //
  }): Observable<IAPIConectivityDef> {
    return this._auth._post('connectivity/', data);
  }

  edit(
    id: number,
    data: {
      alias: string;
      account: number;
      table_id: number;
      connectivity_type: number;
      is_active?: boolean;
      is_deleted?: boolean;
    },
  ): Observable<IAPIConectivityDef> {
    return this._auth._patch(`connectivity/${id}`, data);
  }

  delete(id: number): Observable<IAPIConectivityDef> {
    return this._auth._patch(`connectivity/${id}`, { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } });
  }

  deleteBatch(data: { conectivities: number[] }): Observable<IAPIConectivityDef> {
    return this._auth._delete(`conectivity/list/`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

@Injectable({
  providedIn: 'root',
})
class ProjectTypesAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIProjectTypeDef> | IAPIProjectTypeDef> {
    return this._auth._get(`project-type/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  create(data: {
    name: string; // (required)
    description?: string; //
  }): Observable<IAPIProjectTypeDef> {
    return this._auth._post('project-type/', data);
  }

  edit(
    id: number,
    data: {
      name?: string; //
      description?: string; //
    },
  ): Observable<IAPIProjectTypeDef> {
    return this._auth._patch(`project-type/${id}`, data);
  }

  delete(id: number): Observable<IAPIProjectTypeDef> {
    return this._auth._patch(`project-type/${id}`, { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } });
  }
}
@Injectable({
  providedIn: 'root',
})
class ScanTypesAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIScanTypeDef> | IAPIScanTypeDef> {
    return this._auth._get(`scan/type${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  create(data: {
    name: string; // (required)
    description?: string; //
  }): Observable<IAPIScanTypeDef> {
    return this._auth._post('scan/type', data);
  }

  edit(
    id: number,
    data: {
      name?: string; //
      description?: string; //
    },
  ): Observable<IAPIScanTypeDef> {
    return this._auth._patch(`scan/type/${id}`, data);
  }

  delete(id: number): Observable<IAPIScanTypeDef> {
    return this._auth._patch(`scan/type/${id}`, { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } });
  }
}

@Injectable({
  providedIn: 'root',
})
class ScanTracksAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIScanTrackDef> | IAPIScanTrackDef> {
    return this._auth._get(`scan/track/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  create(data: {
    scan: number; // (required)
    device: number; //
    connectivity: number; //
  }): Observable<IAPIScanTrackDef> {
    return this._auth._post('scan/track/', data);
  }
  /*
        edit(id: number,
            data: {
                name?: string,//
                description?: string,//
            }): Observable<IAPIScanTypeDef> {

            return this._auth._patch(`scan/track/${id}`, data);
        } */

  /*     delete(id: number): Observable<IAPIScanTypeDef> {
            return this._auth._patch(`scan/track/${id}`, { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } });
        }
     */
}

@Injectable({
  providedIn: 'root',
})
class ScanPlansAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIScanPlanDef> | IAPIScanPlanDef> {
    return this._auth._get(`scan/plan/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  create(data: {
    scan: number; // (required)
  }): Observable<IAPIScanPlanDef> {
    return this._auth._post('scan/plan/', data);
  }
  /*
        edit(id: number,
            data: {
                name?: string,//
                description?: string,//
            }): Observable<IAPIScanPlanDef> {

            return this._auth._patch(`scan/track/${id}`, data);
        } */

  delete(id: number): Observable<IAPIScanPlanDef> {
    return this._auth._delete(`scan/plan/${id}` /* , { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } } */);
  }
}

@Injectable({
  providedIn: 'root',
})
class StandardsAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPITestStandardDef> | IAPITestStandardDef> {
    return this._auth._get(`test/standard/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
class TestsAPIService {
  constructor(
    private _auth: AuthService,
    public standards: StandardsAPIService,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
class ScansAPIService {
  constructor(
    private _auth: AuthService,
    public types: ScanTypesAPIService,
    public tracks: ScanTracksAPIService,
    public plans: ScanPlansAPIService,
  ) {}

  get(params?: {
    account?: number;
    last?: boolean;
    id?: number;
    order?: string;
    limit?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIScanDef> | IAPIScanDef> {
    if (!params?.order) {
      params = { ...params, ...{ order: 'id' } };
    }
    return this._auth._get(`scan/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  getIps(data: { scan: number; device_token: string }): Observable<any> {
    return this._auth._post(`scan/get_ips/`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  getIpsEndpoint(data: { scan: number }): Observable<any> {
    return this._auth._get(`scan/scan_task/${data?.scan}`, {}, { headers: { 'Content-Type': 'application/json' } });
  }

  startCapture(data: { device_token: string; scan: number }): Observable<any> {
    return this._auth._post(`scan/start_capture/`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  nextSegment(data: { device_token: string; scan_id: number; project_id: number; use_case_name: string }): Observable<any> {
    return this._auth._post(`scan/next_segment/`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  finishCapture(data: { device_token: string; scan_id: number; project_id: number }): Observable<any> {
    return this._auth._post(`scan/finish_capture/`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  create(data: { tags: string[]; scan: number; project: number; scan_type: number }): Observable<IAPIScanDef> {
    return this._auth._post('scan/', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  generate(data: {
    device_token: string;
    scan: {
      tags: string[];
      account: number;
      project: number;
      scan_type: number;
    };
    scan_plan: {
      standards: string[];
      firmwares: string[];
      test_sets: string[];
      test_cases: string[];
      app_tests: string[];
    };
  }): Observable<IAPINewScanDef> {
    return this._auth._post('scan/generate/', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  /*
        Deprecated
    edit(id: number,
        data: {
            name: string,// (required)
            account: number,// (required)
            project_type: number,// (required)
        }): Observable<IAPIScanDef> {

        return this._auth._patch(`scan/${id}`, data, { headers: { 'Content-Type': 'application/json' } });
    } */

  delete(id: number): Observable<IAPIScanDef> {
    return this._auth._delete(`scan/${id}` /* , { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } } */);
  }
}

@Injectable({
  providedIn: 'root',
})
class ProjectsAPIService {
  public devices = {
    addDevice: (
      projectId: number,
      data: {
        devices: number[];
      },
    ): Observable<IAPIProjectDef> => {
      return this._auth._put(`project/add/devices/${projectId}`, data);
    },
    removeDevice: (
      projectId: number,
      data: {
        devices: number[];
      },
    ): Observable<IAPIProjectDef> => {
      return this._auth._put(`project/remove/devices/${projectId}`, data);
    },
  };

  public useCases = {
    addDevice: (
      projectId: number,
      data: {
        use_case: number;
      },
    ): Observable<IAPIProjectDef> => {
      return this._auth._put(`project/add/use-case/${projectId}`, data);
    },
    removeDevice: (
      projectId: number,
      data: {
        use_case: number;
      },
    ): Observable<IAPIProjectDef> => {
      return this._auth._put(`project/remove/use-case/${projectId}`, data);
    },
  };

  constructor(
    private _auth: AuthService,
    public types: ProjectTypesAPIService,
  ) {}

  get(params?: {
    account?: number;
    last?: boolean;
    id?: number;
    order?: string;
    limit?: number;
    filter_str?: string;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIProjectDef> | IAPIProjectDef> {
    if (!params?.order) {
      params = { ...params, ...{ order: 'id' } };
    }
    return this._auth._get(`project/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  create(data: {
    name: string; // (required)
    description?: string; //
    account: number; // (required)
    project_type: number; // (required)
    devices: number[];
    use_cases: number[];
    is_active: boolean;
    is_deleted: boolean;
  }): Observable<IAPIProjectDef> {
    return this._auth._post('project/', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  edit(
    id: number,
    data: {
      name: string; // (required)
      account: number; // (required)
      project_type: number; // (required)
    },
  ): Observable<IAPIProjectDef> {
    return this._auth._patch(`project/${id}`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  delete(id: number): Observable<IAPIProjectDef> {
    return this._auth._patch(`project/${id}`, { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } });
  }

  deleteBatch(data: { projects: number[] }): Observable<IAPIProjectDef> {
    return this._auth._delete(`project/list/`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

@Injectable({
  providedIn: 'root',
})
class UseCasesAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
    filter_str?: string;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIUseCaseDef>> {
    return this._auth._get(`use-case/${params?.id || ''}`, params || {}).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  create(data: {
    name: string; // (required)
    description: string; // (required)
    is_active?: boolean;
    is_deleted?: boolean;
  }): Observable<IAPIUseCaseDef> {
    return this._auth._post('use-case/', data);
  }

  edit(
    id: number,
    data: {
      name?: string; //
      description?: string; //
    },
  ): Observable<IAPIUseCaseDef> {
    return this._auth._patch(`use-case/${id}`, data);
  }

  delete(id: number): Observable<IAPIUseCaseDef> {
    return this._auth._patch(`use-case/${id}`, { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } });
  }
}

@Injectable({
  providedIn: 'root',
})
class DeviceTypesAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    id?: number;
    limit?: number;
    filter_str?: string;
    order?:
      | 'id'
      | 'name'
      | 'description'
      | 'is_active'
      | 'createdate'
      | 'createby'
      | '-typeid'
      | '-name'
      | '-description'
      | '-is_active'
      | '-createdate'
      | '-createby';
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIDeviceTypeDef>> {
    return this._auth._get(`device-type/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  create(data: {
    name: string; // (required)
    description?: string; //
    is_active?: boolean; //
    is_deleted?: boolean; //
  }): Observable<IAPIDeviceTypeDef> {
    return this._auth._post('device-type/', data);
  }

  edit(
    id: number,
    data: {
      name?: string; //
      description?: string; //
      is_active?: boolean; //
      is_deleted?: boolean; //
    },
  ): Observable<IAPIDeviceTypeDef> {
    return this._auth._patch(`device-type/${id}`, data);
  }

  delete(id: number): Observable<IAPIDeviceTypeDef> {
    return this._auth._patch(`device-type/${id}`, { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } });
  }
}

@Injectable({
  providedIn: 'root',
})
class DevicesAPIService {
  public conectivities = {
    assign: (
      deviceId: number,
      data: {
        connectivity: number;
      },
    ): Observable<IAPIProjectDef> => {
      return this._auth._put(`device/assign-connectivity/${deviceId}`, data);
    },
  };

  constructor(
    private _auth: AuthService,
    public types: DeviceTypesAPIService,
  ) {}

  get(params?: {
    account?: number;
    limit?: number;
    id?: number;
    order?:
      | 'id'
      | 'name'
      | 'description'
      | 'account'
      | 'device_type'
      | 'icon_url_image'
      | 'connectivity'
      | 'is_active'
      | 'createdate'
      | 'createby'
      | '-id'
      | '-name'
      | '-description'
      | '-account'
      | '-device_type'
      | '-icon_url_image'
      | '-connectivity'
      | '-is_active'
      | '-created_at'
      | '-updated_at'
      | '-createby';
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIDeviceDef> | IAPIDeviceDef> {
    return this._auth._get(`device/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  create(data: {
    name: string; // (required)
    description: string; // (required)
    account: number; // (required)
    device_type: number; // (required)
    icon_image?: {
      blob: Blob;
      name: string;
    }; //
    connectivity: number;
    is_active?: boolean; //
    is_deleted?: boolean; //
  }): Observable<IAPIDeviceDef> {
    return this._auth._post('device/', data);
  }

  edit(
    id: number,
    data: {
      name?: string; // (required)
      description?: string; // (required)
      account?: number; // (required)
      device_type?: number; // (required)
      icon_image?: {
        blob: Blob;
        name: string;
      }; //
      connectivity?: number; //
      is_active?: boolean; //
      is_deleted?: boolean; //
    },
  ): Observable<IAPIDeviceDef> {
    return this._auth._patch(`device/${id}`, data);
  }

  delete(id: number): Observable<IAPIDeviceDef> {
    return this._auth._patch(`device/${id}`, { is_deleted: true }, { headers: { 'Content-Type': 'application/json' } });
  }

  deleteBatch(data: { devices: number[] }): Observable<IAPIDeviceDef> {
    return this._auth._delete(`device/list/`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

@Injectable({
  providedIn: 'root',
})
class IndustriesAPIService {
  constructor(
    private _auth: AuthService,
    private http: HttpClient,
  ) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
    page?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIIndustryDef> | IAPIIndustryDef> {
    return this._auth._get(`industry/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  list(params?: {
    id?: number;
    order?: string;
    limit?: number;
    page?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPIIndustryDef> | IAPIIndustryDef> {
    return this.http.get('/assets/industries.json', {}) as any;

    // return this._auth._get(`industry/list/${params?.id || ''}`, params).pipe(map((val: any) => {
    //     return val?.result || val;
    // }));
  }
}

@Injectable({
  providedIn: 'root',
})
class CitiesAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
    page?: number;
    state?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPICountryDef> | IAPICountryDef> {
    return this._auth._get(`state-city/list/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
class StatesAPIService {
  constructor(
    private _auth: AuthService,
    public cities: CitiesAPIService,
  ) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
    page?: number;
    country?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPICountryDef> | IAPICountryDef> {
    return this._auth._get(`country-state/list/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }
}
@Injectable({
  providedIn: 'root',
})
class CountriesAPIService {
  constructor(
    private _auth: AuthService,
    public states: StatesAPIService,
  ) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
    page?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPICountryDef> | IAPICountryDef> {
    return this._auth._get(`country/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  list(params?: {
    id?: number;
    order?: string;
    limit?: number;
    page?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPICountryDef> | IAPICountryDef> {
    return this._auth._get(`country/list/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
class PlansAPIService {
  constructor(private _auth: AuthService) {}

  get(params?: {
    id?: number;
    order?: string;
    limit?: number;
    page?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPISubscriptionPlanDef> | IAPISubscriptionPlanDef> {
    return this._auth._get(`subscription-plan/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }

  list(params?: {
    id?: number;
    order?: string;
    limit?: number;
    page?: number;
  }): Observable<IAPIPaginationHandlerDefApiReturn<IAPISubscriptionPlanDef> | IAPISubscriptionPlanDef> {
    return this._auth._get(`subscription-plan/list/${params?.id || ''}`, params).pipe(
      map((val: any) => {
        return val?.result || val;
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ClientAPIService {
  constructor(
    public devices: DevicesAPIService,
    public simCards: SIMCardsAPIService,
    public vpnDevices: VPNDevicesAPIService,
    public vpnRouter: VPNRouterAPIService,
    public projects: ProjectsAPIService,
    public scans: ScansAPIService,
    public tests: TestsAPIService,
    public useCases: UseCasesAPIService,
    public conectivities: ConectivitiesAPIService,
    public industries: IndustriesAPIService,
    public countries: CountriesAPIService,
    public subscriptionPlans: PlansAPIService,
  ) {}
}
