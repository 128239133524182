const isObjectLike = (value: unknown) => {
  return typeof value === 'object' && value !== null;
};

export const convertKeysToCamelCase = (obj: unknown): unknown => {
  if (Array.isArray(obj)) {
    return obj.map((v) => convertKeysToCamelCase(v));
  } else if (isObjectLike(obj)) {
    return Object.entries(obj).reduce((result, [key, value]) => {
      const camelKey = key.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
      return { ...result, [camelKey]: convertKeysToCamelCase(value) };
    }, {});
  }

  return obj;
};

export const toSnakeCase = (str: string): string => {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const convertKeysToSnakeCase = (obj: unknown): unknown => {
  if (Array.isArray(obj)) {
    return obj.map((val) => convertKeysToSnakeCase(val));
  } else if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      return {
        ...acc,
        [toSnakeCase(key)]: convertKeysToSnakeCase(value),
      };
    }, {});
  }
  return obj;
};
