import {Component, HostListener, OnInit, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import {ModalController, Platform} from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from './services/api/core/api.service';
import { LanguageService } from './services/language/language.service';
import { register } from 'swiper/element/bundle';
import { AuthService } from './services/api/core/auth.service';
import { Store } from '@ngrx/store';
import * as AuthActions from '@app/shared/store/user/user.actions';
import {selectLoading, selectSystemLoading} from '@app/shared/store/user/user.reducer';
import {AlertModalComponent} from "@app/components/modals/alert-modal/alert-modal.component";

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  subscriptions: Subscription[] = [];
  initialized = false;

  public readonly sessionLoading$: Observable<boolean> = this.store.select(selectLoading);;
  public readonly systemLoading$: Observable<boolean> = this.store.select(selectSystemLoading);

  constructor(
    private platform: Platform,
    private language: LanguageService,
    private api: ApiService,
    private router: Router,
    private swPush: SwPush,
    private readonly authApiService: AuthService,
    private readonly store: Store,
    private readonly modalCtrl: ModalController
  ) {
    this.getStoredTokens();
    this.initializeApp();
  }

  private async getStoredTokens(): Promise<void> {
    const tokens = await this.authApiService.getTokensFromStorage();
    this.store.dispatch(AuthActions.setLoader({ data: true }));

    if (tokens) {
      this.store.dispatch(
        AuthActions.signInSuccess({
          data: tokens,
          redirection: false,
        }),
      );
    } else {
      if (!this.router.url.includes('/login')) {
      }

      this.store.dispatch(AuthActions.setLoader({ data: false }));
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.language.setInitialAppLanguage();
      this.initialized = true;
    });
  }
}
