export interface SkipUrl {
  method: string;
  url: string;
}

export const API_SKIP_URLS: SkipUrl[] = [
  {
    method: 'POST',
    url: '/scan/scan_plan',
  },
  {
    method: 'GET',
    url: '/check',
  },
  {
    method: 'POST',
    url: '/user/code_verification',
  },
  {
    method: 'POST',
    url: '/user/password/update',
  },
  {
    method: 'POST',
    url: '/user/email-update-request',
  },
  {
    method: 'POST',
    url: '/user/email-update-confirm',
  },
  {
    method: 'POST',
    url: 'user/invite',
  },
];
