import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiConfig } from 'src/environments/environment';

const _processFormData = (data: any, contentType: any) => {
  let formData = new FormData();
  if (contentType == 'application/json') {
    formData = data;
  } else {
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== null) {
        if (data[key].blob) {
          // blob name
          // formData.set(key, data[key].blob, data[key].name);
          formData.append(key, data[key].blob, data[key].name);
        } else if (Array.isArray(data[key])) {
          // https://www.py4u.net/discuss/285583
          for (const value of data[key]) {
            formData.append(key + '[]', value);
          }
        } else {
          // formData.set(key, data[key]);
          formData.append(key, data[key]);
        }
      }
    });
  }
  return formData;
};

@Injectable({
  providedIn: 'root',
})
export class HttpsService {
  constructor(private http: HttpClient) {}

  _get(endpoint: any, data: any, options: any) {
    /*const params = [];
    //_processFormData(data || {}, ((options || {}).headers || {})['Content-Type']).forEach((value, key) => params.push(encodeURIComponent(key) + '=' + encodeURIComponent(value)));
    if (params.length > 0) {
      endpoint += (endpoint.indexOf('?') >= 0 ? '&' : '?') + params.join('&');
    }*/
    return this._makeRequest('GET', endpoint, data, options);
  }

  _post(endpoint: any, data: any, options: any) {
    const formData = _processFormData(data || {}, ((options || {}).headers || { 'Content-Type': 'multipart/form-data' })['Content-Type']);
    return this._makeRequest('POST', endpoint, formData, options);
  }

  _patch(endpoint: any, data: any, options: any) {
    const formData = _processFormData(data || {}, ((options || {}).headers || { 'Content-Type': 'multipart/form-data' })['Content-Type']);
    return this._makeRequest('PATCH', endpoint, formData, options);
  }

  _put(endpoint: any, data: any, options: any) {
    const formData = _processFormData(data || {}, ((options || {}).headers || { 'Content-Type': 'multipart/form-data' })['Content-Type']);
    return this._makeRequest('PUT', endpoint, formData, options);
  }

  _delete(endpoint: any, data: any, options: any) {
    return this._makeRequest('DELETE', endpoint, data, options);
  }

  private _makeRequest(type: string = 'GET', endPoint: string, data: any, options: any): Observable<any> {
    endPoint = `${apiConfig.baseUrl + endPoint}`;
    let request: Observable<any>;
    if (type === 'POST') {
      request = this.http.post(endPoint, data, options);
    } else if (type === 'PUT') {
      request = this.http.put(endPoint, data, options);
    } else if (type === 'PATCH') {
      request = this.http.patch(endPoint, data, options);
    } else if (type === 'DELETE') {
      request = this.http.delete(endPoint, { ...options, ...{ body: data } });
    } else {
      options.params = data;
      request = this.http.get(endPoint, options);
    }
    return request.pipe(
      map((response: any) => {
        //let error = 'SYSTEM_ERROR';
        if (response.status >= 200 && response.status < 300) {
          //if (response.body.result == true) {
          return response?.body?.data || response?.body;
          /* } else {
          error = response.body.result;
        } */
        } else {
          throw new Error(response.body.data || response.body);
        }
      }),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }
}
