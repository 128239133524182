/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FindingTemplate { 
    code?: string;
    readonly createdAt?: string;
    cvssRisk: FindingTemplate.CvssRiskEnum;
    cvssScore: number;
    cvssString: string;
    description: string;
    readonly id?: string;
    impact: string;
    mapping: string;
    overallRisk: FindingTemplate.OverallRiskEnum;
    probability: string;
    recommendations: string;
    references: object;
    summary: string;
    title: string;
    readonly updatedAt?: string;
    version: string;
}
export namespace FindingTemplate {
    export type CvssRiskEnum = 'High' | 'Medium' | 'Low' | 'Info';
    export const CvssRiskEnum = {
        High: 'High' as CvssRiskEnum,
        Medium: 'Medium' as CvssRiskEnum,
        Low: 'Low' as CvssRiskEnum,
        Info: 'Info' as CvssRiskEnum
    };
    export type OverallRiskEnum = 'High' | 'Medium' | 'Low' | 'Info';
    export const OverallRiskEnum = {
        High: 'High' as OverallRiskEnum,
        Medium: 'Medium' as OverallRiskEnum,
        Low: 'Low' as OverallRiskEnum,
        Info: 'Info' as OverallRiskEnum
    };
}


