/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ScanPlanType } from './scanPlanType';
import { Project } from './project';
import { ScanPlanTagsInner } from './scanPlanTagsInner';
import { ProjectDevice } from './projectDevice';
import { AccountRead } from './accountRead';
import { ScanPlanBTest } from './scanPlanBTest';
import { ScanPlanResult } from './scanPlanResult';
import { TrafficSegmentWithCurrentRetrieve } from './trafficSegmentWithCurrentRetrieve';


export interface ScanPlan { 
    bTests: Array<ScanPlanBTest>;
    createdBy: AccountRead;
    readonly devices?: Array<ProjectDevice>;
    duration?: string | null;
    finishedAt?: string | null;
    readonly id?: string;
    isDownloadAvailable?: boolean;
    project: Project;
    result?: ScanPlanResult;
    readonly tags?: Array<ScanPlanTagsInner>;
    readonly trafficSegments?: Array<TrafficSegmentWithCurrentRetrieve>;
    type?: ScanPlanType;
}

