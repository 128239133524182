/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ScheduledSubscription } from './scheduledSubscription';
import { Subscription } from './subscription';


export interface CompanyRead { 
    address: string;
    alias?: string | null;
    city: string;
    country: string;
    readonly createdAt?: string;
    readonly id?: string;
    industry: string;
    name: string;
    phoneNumber: string;
    postalCode: string;
    readonly scanTokensBalance?: string;
    scheduledSubscription: ScheduledSubscription;
    state: string;
    subscription: Subscription;
    readonly updatedAt?: string;
    vat: string;
}

