/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyCreate } from './companyCreate';


export interface UserCreateSuperAdmin { 
    alias: string;
    company: CompanyCreate;
    email: string;
    emailVerificationToken: string;
    firstName?: string;
    lastName?: string;
    password: string;
}

