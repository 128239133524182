export interface DecodedToken {
  accountId: string;
  companyAlias: string;
  exp: number;
  jti: string;
  tokenType: string;
  userId: string;
}

export enum RolesKeywords {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Tester = 'TESTER',
  Viewer = 'VIEWER',
  Finance = 'FINANCE',
}
