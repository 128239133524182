import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAPIVersionDef } from 'src/app/api_interfaces/core';
import packageInfo from '../../../../../package.json';
import { AuthService } from '../core/auth.service';

const version = packageInfo.version;

@Injectable({
  providedIn: 'root',
})
export class SystemAPIAgreements {
  constructor(private _auth: AuthService) {}

  /* get(data: { query: IAPIRequestQuery | string }): Observable<IAPIAgreementDef[]> {
    data.query = JSON.stringify(data.query);
    return this._auth._post('agreements/get.json', data);
  } */
}

@Injectable({
  providedIn: 'root',
})
export class SystemAPIConfig {
  config = {};

  permissions = {
    has: (permission, userPermissions) => {
      return Array.isArray(userPermissions) && (userPermissions || []).find((item) => item.id == permission.id) != undefined;
    },
    get type() {
      return this.config.ui_permissions;
    },
  };

  constructor(private _auth: AuthService) {}

  public initialize() {
    // load privileges from server
    return this.loadConfig().then((result: any) => {
      this.config = result;
    });
  }

  public loadConfig() {
    const getJSON = function (url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'json';
      xhr.onload = function () {
        const status = xhr.status;
        if (status === 200) {
          callback(null, xhr.response);
        } else {
          callback(status, xhr.response);
        }
      };
      xhr.send();
    };
    return new Promise((resolve, reject) => {
      getJSON('assets/config.json', (error, result) => {
        if (error) {
          reject();
        } else {
          resolve(result);
        }
      });
    });
  }
  /* get(data: { query: IAPIRequestQuery | string }): Observable<IAPIAgreementDef[]> {
    data.query = JSON.stringify(data.query);
    return this._auth._post('agreements/get.json', data);
  } */
}

@Injectable({
  providedIn: 'root',
})
export class SystemAPIService {
  public version: string = version;
  private appVersion$: BehaviorSubject<IAPIVersionDef> = new BehaviorSubject<IAPIVersionDef>({
    version,
    download_url: {
      ios: 'https://appstore.com/',
      android: 'https://play.google.com/store/apps/',
    },
  });

  constructor(
    private auth: AuthService,
    public agreements: SystemAPIAgreements,
    public config: SystemAPIConfig,
  ) {}

  public initialize() {
    // load privileges from server
    return this.config.initialize();
  }

  public initialized() {
    return this.auth._initialized();
  }

  public checkForUpdates() {}

  public registerPushToken(data: { device_token: string }): Observable<boolean> {
    return this.auth._post('device_token/create', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  public unregisterPushToken(data: { device_token: string }): Observable<boolean> {
    return this.auth._post('device_token/delete', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  public getBackendVersionNumber(): Observable<any> {
    return this.auth._get('version').pipe(
      map((val: any) => {
        return val?.data || val;
      }),
    );
  }
}
