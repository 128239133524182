/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ScanPlanUpdate { 
    readonly createdAt?: string;
    createdBy: string;
    duration: string;
    finishedAt?: string | null;
    readonly id?: string;
    isDownloadAvailable?: boolean;
    project: string;
    result?: string | null;
    tags?: Array<string>;
    testNode?: string | null;
    readonly tokens?: Array<string>;
    type: string;
    readonly updatedAt?: string;
}

