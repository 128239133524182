/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectedPlan } from './selectedPlan';


export interface Subscription { 
    readonly id?: string;
    isCanceled?: boolean;
    paidUntil?: string | null;
    selectedPlan?: SelectedPlan;
}

