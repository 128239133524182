import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpsService } from './https.service';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '../../utils/storage.service';
import { IAPIUserInformationDef } from 'src/app/api_interfaces/core';
import { apiConfig } from 'src/environments/environment';
import { ApiService } from '../api.base.service';
import { IonicStorageService } from '../../utils/ionic-storage.service';
import { AccountTokenObtainPair, AccountTokenObtainPairResponse, CustomTokenRefresh } from '@app/shared/openapi';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedOutUserInfo = { id: 0 };
  private authToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private userInformation$: BehaviorSubject<any> = new BehaviorSubject<any>(this.loggedOutUserInfo);
  private initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private storage: StorageService,
    private router: Router,
    private http: HttpsService,
    private readonly apiService: ApiService,
    private readonly ionicStorageService: IonicStorageService,
  ) {
    if (apiConfig.IS_APP) {
      this.loadAuthToken();
    } else {
      this.loadUserInfo();
    }
  }

  private loadAuthToken() {
    this.storage.get('authToken').then((storedToken) => {
      if (storedToken) {
        this.authToken$.next(storedToken);
      }
      this.authToken$.subscribe({
        next: (token) => {
          this.storage.set('authToken', token);
        },
      });
      this.loadUserInfo();
    });
  }

  private loadUserInfo() {
    this.storage.get('userInfo').then((storedUserInfo) => {
      if (storedUserInfo) {
        this.userInformation$.next(storedUserInfo);
      }
      this.userInformation$.subscribe({
        next: (userInfo) => this.storage.set('userInfo', userInfo),
      });
      this.initialized$.next(true);
    });
  }

  private signAndSecureRequest(callback: any, endPoint: any, data: any, options: any) {
    options = options || {};
    options.observe = 'response';
    options.headers = Object.assign({}, options.headers || {}, {
      // 'Content-Type': (options.headers || {})['Content-Type'] || (apiConfig.DEFAULT_CONTENT_TYPE || 'multipart/form-data')
    });
    if (apiConfig.DEFAULT_CONTENT_TYPE != 'multipart/form-data') {
      options.headers['Content-Type'] = apiConfig.DEFAULT_CONTENT_TYPE;
    }
    options.responseType = options.responseType || apiConfig.DEFAULT_RESPONSE_TYPE || 'json';
    let suscription: any;
    return new Observable((subscriber) => {
      suscription = this.initialized$.subscribe({
        next: (value) => {
          if (value === true) {
            setTimeout(() => suscription.unsubscribe(), 10);
            if (apiConfig.IS_APP) {
              //options.headers = Object.assign({}, options.headers || {}, { 'apiaf': 'app' });
              if ((this.authToken$.value || '').length > 0) {
                options.headers = Object.assign({}, options.headers || {}, {
                  Authorization: 'Bearer ' + this.authToken$.value,
                });
              }
            }
            // here call callback
            callback(endPoint, data, options)
              .pipe(
                map((response: any) => {
                  if (
                    endPoint === 'auth/signin' ||
                    endPoint === 'auth/token/verify' ||
                    /* endPoint === 'auth/signup' || */ endPoint === 'auth/token/refresh' ||
                    endPoint === 'auth/token'
                  ) {
                    if (typeof response?.access === 'string') {
                      this.authToken$.next(response.access);
                      delete response.access;
                    }
                    if (endPoint === 'auth/signup' || endPoint === 'auth/signin') {
                      this.userInformation$.next(response);
                    }
                  }
                  return response;
                }),
                catchError((error) => {
                  if (
                    (error instanceof Error && error.message === 'YOU_ARE_NOT_LOGGED_IN') ||
                    (error as any)?.error?.code === 'token_not_valid'
                  ) {
                    this._resetAuthState();
                  }
                  return throwError(error);
                }),
              )
              .toPromise()
              .then((result: any) => {
                subscriber.next(result);
              })
              .catch((error: any) => {
                subscriber.error(error);
              })
              .finally(() => {
                subscriber.complete();
              });
          }
        },
      });
    });
  }

  _get(endPoint: any, data?: any, options?: any) {
    return this.signAndSecureRequest(
      (_endPoint: any, _data: any, _options: any) => this.http._get(_endPoint, _data, _options),
      endPoint,
      data,
      options,
    ) as Observable<any>;
  }

  _post(endPoint: any, data: any, options?: any) {
    return this.signAndSecureRequest(
      (_endPoint: any, _data: any, _options: any) => this.http._post(_endPoint, _data, _options),
      endPoint,
      data,
      options,
    ) as Observable<any>;
  }

  _patch(endPoint: any, data: any, options?: any) {
    return this.signAndSecureRequest(
      (_endPoint: any, _data: any, _options: any) => this.http._patch(_endPoint, _data, _options),
      endPoint,
      data,
      options,
    ) as Observable<any>;
  }

  _put(endPoint: any, data: any, options?: any) {
    return this.signAndSecureRequest(
      (_endPoint: any, _data: any, _options: any) => this.http._put(_endPoint, _data, _options),
      endPoint,
      data,
      options,
    ) as Observable<any>;
  }

  _delete(endPoint: any, data?: any, options?: any) {
    return this.signAndSecureRequest(
      (_endPoint: any, _data: any, _options: any) => this.http._delete(_endPoint, _data, _options),
      endPoint,
      data,
      options,
    ) as Observable<any>;
  }

  _logout() {
    return new Promise((resolve, reject) => {
      /* this._post('account/logout.json', { dummy: 'dummy' })
                                .toPromise()
                                .then((response) => {
                                  if (response === true) {
                                    this._resetAuthState();
                                    resolve('');
                                  } else {
                                    reject();
                                  }
                                }).catch((error) => reject(error)); */
      this._resetAuthState();
      resolve(true);
    });
  }

  _refreshAuth() {
    return new Promise((resolve, reject) => {
      this._post('auth/token/refresh', {
        refresh: this.authToken$.value || 'dummy',
      })
        .toPromise()
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  _getUserInformation(): Observable<IAPIUserInformationDef> {
    return this.userInformation$.asObservable();
  }

  _initialized() {
    return this.initialized$.asObservable();
  }

  _resetAuthState() {
    this.userInformation$.next(this.loggedOutUserInfo);
    this.authToken$.next('');
  }

  public signIn(payload: AccountTokenObtainPair): Observable<{ access: string; refresh: string }> {
    return this.apiService.post('api/token', payload);
  }

  public refreshTokens(refresh: string): Observable<{ access: string; refresh: string }> {
    return this.apiService.post('api/token/refresh', { refresh });
  }

  public setTokensToStorage(payload: AccountTokenObtainPairResponse | CustomTokenRefresh) {
    this.ionicStorageService.setItem('bunkai-session', JSON.stringify(payload));
  }

  public async getTokensFromStorage(): Promise<{
    access: string;
    refresh: string;
  } | null> {
    return (await this.ionicStorageService.getItem('bunkai-session')) as {
      access: string;
      refresh: string;
    };
  }

  public async deleteAuthTokensFromStorage() {
    return await this.ionicStorageService.removeItem('bunkai-session');
  }

  public logout(): void {
    this.ionicStorageService.removeItem('bunkai-session');
    this.router.navigateByUrl('/login');
  }
}
