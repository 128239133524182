import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { selectAccount } from '@app/shared/store/user/user.reducer';
import { RolesKeywords } from '@app/shared/store/user/utils/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
  name: 'showForRole',
  standalone: true,
})
export class ShowForRolePipe implements PipeTransform {
  private currentRole: RolesKeywords | null = null;

  constructor(private store: Store) {
    this.store
      .select(selectAccount)
      .pipe(
        map((account) => account?.role?.keyword as RolesKeywords),
        untilDestroyed(this),
      )
      .subscribe((role) => (this.currentRole = role));
  }

  transform(allowedRoles: RolesKeywords[]): boolean {
    return allowedRoles.includes(this.currentRole);
  }
}
