/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectDevice } from './projectDevice';
import { TrafficSegmentWithCurrentRetrieve } from './trafficSegmentWithCurrentRetrieve';


export interface ProjectRetrieve { 
    description?: string | null;
    readonly devices?: Array<ProjectDevice>;
    readonly id?: string;
    name: string;
    readonly trafficSegments?: Array<TrafficSegmentWithCurrentRetrieve>;
    type: string;
}

