import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { apiConfig } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly baseUrl = apiConfig.baseUrl;

  constructor(private _http: HttpClient) {}

  post<T = any>(url: string, postData = {}): Observable<T> {
    const apiUrl = `${this.baseUrl}${url}`;
    return this._http.post<T>(apiUrl, postData).pipe(catchError(this._handleError));
  }

  get<T = any>(url: string, params?: any): Observable<T> {
    const apiUrl = `${this.baseUrl}${url}`;
    return this._http.get<T>(apiUrl, { params }).pipe(catchError(this._handleError));
  }

  put<T = any>(url: string, body: any): Observable<T> {
    const apiUrl = `${this.baseUrl}${url}`;
    return this._http.put<T>(apiUrl, body).pipe(catchError(this._handleError));
  }

  patch<T = any>(url: string, body: any): Observable<T> {
    const apiUrl = `${this.baseUrl}${url}`;
    return this._http.patch<T>(apiUrl, body).pipe(catchError(this._handleError));
  }

  delete<T = any>(url: string): Observable<T> {
    const apiUrl = `${this.baseUrl}${url}`;
    return this._http.delete<T>(apiUrl).pipe(catchError(this._handleError));
  }

  private _handleError(error: HttpErrorResponse) {
    console.error('ApiService: _handleError', error);
    return throwError(error);
  }
}
