import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class Toast {
  private readonly types = {
    error: 'error',
    success: 'success',
    warning: 'warn',
  };

  constructor(
    private toastController: ToastController,
    private translate: TranslateService,
    private messageService: MessageService,
  ) {}

  public present(message: string, type: string = 'success', duration: number = 8000): void {
    this.messageService.add({
      severity: this.types[type],
      summary: this.translate.instant(type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()),
      detail: this.translate.instant(message),
      life: duration,
    });
  }

  public success(message: string, duration?: number): void {
    this.present(message, 'success', duration);
  }

  public error(message: string, duration?: number): void {
    this.present(message, 'error', duration);
  }

  public warning(message: string, duration?: number): void {
    this.present(message, 'warning', duration);
  }
}
