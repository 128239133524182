/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { UserRead } from './userRead';
import { AccountGroupMinimized } from './accountGroupMinimized';
import { CompanyRead } from './companyRead';


export interface AccountRead { 
    company: CompanyRead;
    readonly createdAt?: string;
    readonly groups?: Array<AccountGroupMinimized>;
    readonly id?: string;
    isActive?: boolean;
    isDeleted?: boolean;
    role: Role;
    readonly updatedAt?: string;
    user: UserRead;
}

