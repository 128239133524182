/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyAccountRead } from './companyAccountRead';


export interface UserAccountListList200Response { 
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<CompanyAccountRead>;
}

