/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NetworkEvent { 
    apn?: string | null;
    cause: string;
    ccs?: string | null;
    readonly createdAt?: string;
    eventType: string;
    externalId: string;
    readonly id?: string;
    imei?: string | null;
    imsi: string;
    ipv4?: string | null;
    ipv6?: string | null;
    mccs?: string | null;
    mncs?: string | null;
    networkCountryIso2?: string | null;
    networkCountryName?: string | null;
    operatorName: string;
    ratType?: string | null;
    simCard: string;
    tadig: string;
    timestamp: string;
    readonly updatedAt?: string;
}

