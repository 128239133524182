import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from '@app/services/modal/modal.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class ErrorModalComponent {
  @Input() data: {
    error: unknown;
  };

  constructor(private readonly modalService: ModalService) {}

  public close(): void {
    this.modalService.dismissModal();
  }
}
